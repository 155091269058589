import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.LCRMT;

export default [
  {
    id: `/${path}/dashboard`,
    title: <Link className="route_link" to={`/${path}/dashboard`}>Dashboard</Link>,
    icon: 'bc-icon-dashboard',
    permission: permissions.LCRMTBox
  },
  {
    id: `/${path}/alerts`,
    title: 'Alert Monitor',
    icon: 'bc-icon-bell-outline',
    permission: permissions.LCRMTAlertMonitor,
    data: [
      {
        id: `/${path}/alerts/chi-square-alerts`,
        title: <Link to={`/${path}/alerts/chi-square-alerts`}>Chi Square Alerts</Link>,
        permission: permissions.LCRMTAdmin
      },
      {
        id: `/${path}/alerts/daily-alerts/opposite-betting-accounts`,
        title: <Link to={`/${path}/alerts/daily-alerts/opposite-betting-accounts`}>Daily Alerts</Link>,
        permission: permissions.LCRMTDailyAlerts
      },
      {
        id: `/${path}/alerts/hourly-alerts/margin-winners`,
        title: <Link to={`/${path}/alerts/hourly-alerts/margin-winners`}>Hourly Alerts</Link>,
        permission: permissions.LCRMTHourlyAlerts
      },
      {
        id: `/${path}/alerts/studio-alerts/cutting-card`,
        title: <Link to={`/${path}/alerts/studio-alerts/cutting-card`}>Studio Alerts</Link>,
        permission: permissions.LCRMTStudioAlerts
      },
      {
        id: `/${path}/alerts/game-integrity-alerts/gaming-margin`,
        title: <Link to={`/${path}/alerts/game-integrity-alerts/gaming-margin`}>Game Integrity Alerts</Link>,
        permission: permissions.LCRMTAdmin
      }
    ]
  },
  {
    id: `/${path}/players`,
    title: <Link className="route_link" to={`/${path}/players`}>Players</Link>,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.LCRMTBox
  },
  {
    id: `/${path}/reports`,
    title: 'Reports',
    icon: 'bc-icon-reports',
    permission: permissions.LCRMTReport,
    data: [
      {
        id: `/${path}/reports/chi-square-reports`,
        title: <Link to={`/${path}/reports/chi-square-reports`}>Chi Square Reports</Link>,
        permission: permissions.LCRMTAdmin
      },
      {
        id: `/${path}/reports/chi-square-lifetime-reports`,
        title: <Link to={`/${path}/reports/chi-square-lifetime-reports`}>Chi Square Lifetime Reports</Link>,
        permission: permissions.LCRMTAdmin
      },
      {
        id: `/${path}/reports/players-bets-report`,
        title: <Link to={`/${path}/reports/players-bets-report`}>Players Bets Reports</Link>,
        permission: permissions.LCRMTReport
      },
      {
        id: `/${path}/reports/players-rounds-report`,
        title: <Link to={`/${path}/reports/players-rounds-report`}>Players Rounds Report</Link>,
        permission: permissions.LCRMTReport
      },
      {
        id: `/${path}/reports/partners-info-report`,
        title: <Link to={`/${path}/reports/partners-info-report`}>Partners Info Report</Link>,
        permission: permissions.LCRMTReport
      }
    ]
  },
  {
    id: `/${path}/equipment-integrity`,
    title: 'Equipment Integrity',
    icon: 'bc-icon-statistics',
    permission: permissions.LCRMTAdmin,
    data: [
      {
        id: `/${path}/equipment-integrity/card-statistics//baccarat`,
        title: <Link to={`/${path}/equipment-integrity/card-statistics/baccarat`}>Card Statistics</Link>,
        permission: permissions.LCRMTAdmin
      },
      {
        id: `/${path}/equipment-integrity/roulette-statistics`,
        title: <Link to={`/${path}/equipment-integrity/roulette-statistics`}>Roulette Statistics</Link>,
        permission: permissions.LCRMTAdmin
      },
      {
        id: `/${path}/equipment-integrity/statistics`,
        title: <Link to={`/${path}/equipment-integrity/statistics`}>Chi Square (Statistics)</Link>,
        permission: permissions.LCRMTAdmin
      }
    ]
  },
  {
    id: `/${path}/settings`,
    title: 'Settings',
    icon: 'bc-icon-settings',
    permission: permissions.LCRMTSettings,
    data: [
      {
        id: `/${path}/settings/notification-settings`,
        title: <Link to={`/${path}/settings/notification-settings`}>Notification Settings</Link>,
        permission: permissions.LCRMTSettings
      },
      {
        id: `/${path}/settings/tables-settings`,
        title: <Link to={`/${path}/settings/tables-settings`}>Tables Settings</Link>,
        permission: permissions.LCRMTAdmin
      }
    ]
  }
];
