export const Product = {
  1: 'SRMT',
  4: 'LCRMT',
  2: 'AML & RG'
};

export const ProductAlert = {
  1: 'srmt',
  4: 'lcrmt',
  2: 'aml'
};

export const ProductsAlertType = {
  1: 'Linked Account',
  2: 'Multi Account',
  3: 'Game Style',
  4: 'Financial',
  5: 'Monitoring',
  6: 'Additional',
  7: 'False Game Style',
  8: 'Opposite Betting Account',
  9: 'Suspicious Tips',
  10: 'Margin Winners',
  11: 'Game Winners',
  12: 'Winners',
  13: 'Straight Up Winners',
  14: 'AML',
  15: 'RG',
  16: 'Single Threshold',
  17: 'Multiple Account Threshold',
  18: 'Multi Account',
  19: 'Monitoring',
  20: 'Suspicious Activity',
  21: 'Advantage Play',
  22: 'Cutting Card Placement',
  23: 'Robot Play'
};

export const ProductsURLAlertType = {
  1: 'linkedAccount',
  2: 'multiAccount',
  3: 'gameStyle',
  4: 'financial',
  5: 'monitoring',
  6: 'additional',
  7: 'falseGameStyle',
  8: 'opposite-betting-accounts',
  9: 'suspicious-tips',
  10: 'margin-winners',
  11: 'game-winners',
  12: 'winners',
  13: 'straight-up-winners',
  14: 'aml',
  15: 'rg',
  16: 'single-threshold',
  17: 'multiple-account-threshold',
  18: 'multiple-account',
  19: 'monitoring',
  20: 'suspicious-activity',
  21: 'advantage-play',
  22: 'cutting-card',
  23: 'robot-play'
};

export const AlertMonitorType = {
  1: 'fraud-monitor',
  2: 'info-monitor',
  3: 'daily-alerts',
  4: 'hourly-alerts',
  5: 'aml',
  6: 'studio-alerts'
};
