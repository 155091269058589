import { initialSectionState, initialSectionWithCountState, initialSectionStateWithError } from 'store/reducers/initialStates';
import { LCRMTPlayersAlertsAfterReview } from 'lib/helpers/changeAlertStatusAfterReview';
import PLAYER_ACTION_TYPES from 'modules/LCRMT/Players/store/actions/player/types';
import { initialSectionWithEditState } from 'lib/core/initialStates';
import type { PlayerState } from 'modules/LCRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import activityHistory from './activityHistoryReducer';
import financialReducer from './financialReducer';

const initialState = (): PlayerState => ({
  deleteLiveGamesRestrictions: initialSectionState(),
  addLiveGamesRestrictions: initialSectionState(),
  liveGamesRestrictions: initialSectionState(),
  globalInfo: initialSectionStateWithError(),
  tablesByCategory: initialSectionState(),
  players: initialSectionWithEditState(),
  gameCategories: initialSectionState(),
  hourlyActivity: initialSectionState(),
  multiAccounts: initialSectionState(),
  globalKPIs: initialSectionState(),
  alerts: initialSectionState(),
  ...activityHistory.initialState,
  ...financialReducer.initialState
});

const actionManager = {
  // global Info
  [PLAYER_ACTION_TYPES.FETCH_GLOBAL_INFO_LCRMT]: (state: PlayerState) => ({
    ...state,
    globalInfo: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null,
      errorStatusCode: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_INFO_LCRMT_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    globalInfo: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      errorStatusCode: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_INFO_LCRMT_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    globalInfo: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      errorStatusCode: payload.errorStatusCode
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GLOBAL_INFO_LCRMT_STATE]: (state: PlayerState) => ({
    ...state,
    globalInfo: initialSectionStateWithError()
  }),
  [PLAYER_ACTION_TYPES.EDIT_GLOBAL_INFO]: (state: PlayerState, payload) => ({
    ...state,
    globalInfo: {
      ...state.globalInfo,
      data: {
        ...state.globalInfo.data,
        ...payload
      }
    }
  }),

  // Multi Accounts
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_MULTI_ACCOUNTS]: (state: PlayerState): PlayerState => ({
    ...state,
    multiAccounts: {
      ...state.multiAccounts,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_MULTI_ACCOUNTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    multiAccounts: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_MULTI_ACCOUNTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    multiAccounts: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_MULTI_ACCOUNTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    multiAccounts: initialSectionState()
  }),

  // Live Games Restrictions
  [PLAYER_ACTION_TYPES.FETCH_LIVE_GAMES_RESTRICTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    liveGamesRestrictions: {
      ...state.liveGamesRestrictions,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LIVE_GAMES_RESTRICTIONS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    liveGamesRestrictions: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LIVE_GAMES_RESTRICTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    liveGamesRestrictions: {
      ...state.liveGamesRestrictions,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LIVE_GAMES_RESTRICTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    liveGamesRestrictions: initialSectionState()
  }),

  // Add Live Games Restrictions
  [PLAYER_ACTION_TYPES.FETCH_ADD_LIVE_GAMES_RESTRICTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    addLiveGamesRestrictions: {
      ...state.addLiveGamesRestrictions,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addLiveGamesRestrictions: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addLiveGamesRestrictions: {
      ...state.addLiveGamesRestrictions,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ADD_LIVE_GAMES_RESTRICTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    addLiveGamesRestrictions: initialSectionWithCountState()
  }),

  // Delete Live Games Restrictions
  [PLAYER_ACTION_TYPES.FETCH_DELETE_LIVE_GAMES_RESTRICTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    deleteLiveGamesRestrictions: {
      ...state.deleteLiveGamesRestrictions,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    deleteLiveGamesRestrictions: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    deleteLiveGamesRestrictions: {
      ...state.deleteLiveGamesRestrictions,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_DELETE_LIVE_GAMES_RESTRICTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    deleteLiveGamesRestrictions: initialSectionWithCountState()
  }),

  // Tables By Category
  [PLAYER_ACTION_TYPES.FETCH_TABLES_BY_CATEGORY]: (state: PlayerState): PlayerState => ({
    ...state,
    tablesByCategory: {
      ...state.tablesByCategory,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_TABLES_BY_CATEGORY_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    tablesByCategory: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_TABLES_BY_CATEGORY_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    tablesByCategory: {
      ...state.tablesByCategory,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_TABLES_BY_CATEGORY_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    tablesByCategory: initialSectionWithCountState()
  }),

  // Game Categories
  [PLAYER_ACTION_TYPES.FETCH_GAME_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    gameCategories: {
      ...state.gameCategories,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GAME_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameCategories: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GAME_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameCategories: {
      ...state.gameCategories,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GAME_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameCategories: initialSectionWithCountState()
  }),

  // hourlyActivity
  [PLAYER_ACTION_TYPES.FETCH_HOURLY_ACTIVITY_LCRMT]: (state: PlayerState) => ({
    ...state,
    hourlyActivity: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_HOURLY_ACTIVITY_LCRMT_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    hourlyActivity: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_HOURLY_ACTIVITY_LCRMT_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    hourlyActivity: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_HOURLY_ACTIVITY_LCRMT_STATE]: (state: PlayerState) => ({
    ...state,
    hourlyActivity: initialSectionState()
  }),

  // global KPIs
  [PLAYER_ACTION_TYPES.FETCH_GLOBAL_KPIS_LCRMT]: (state: PlayerState) => ({
    ...state,
    globalKPIs: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_KPIS_LCRMT_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    globalKPIs: {
      data: payload.data || {},
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_KPIS_LCRMT_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    globalKPIs: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GLOBAL_KPIS_LCRMT_STATE]: (state: PlayerState) => ({
    ...state,
    globalKPIs: initialSectionState()
  }),

  // PlayerList
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYERS]: (state: PlayerState): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYERS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.players.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYERS_APPEND_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.SUCCEED,
      data: [
        ...state.players.data,
        ...payload.data
      ],
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYERS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYERS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    players: initialSectionWithEditState()
  }),

  // Alerts
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYERS_ALERTS]: (state: PlayerState): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYERS_ALERTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYERS_ALERTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.SET_LCRMT_PLAYERS_ALERTS_DATA_CHANGE_AFTER_REVIEW]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      data: LCRMTPlayersAlertsAfterReview(payload.actionType, payload.selectedRows, payload.statuses, state.alerts.data, payload.User, payload.Modified, payload.Assessment, payload.Comment)
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYERS_ALERTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    alerts: initialSectionWithEditState()
  }),
  ...activityHistory.actionManager,
  ...financialReducer.actionManager
};

export default (state: PlayerState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
