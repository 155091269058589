import { isEmptyObj } from './common';

export const deepClone = (obj: {}) => JSON.parse(JSON.stringify(obj));

export const setDefaultSize = ({ columns, sizeParams }) => {
  if (sizeParams && !isEmptyObj(sizeParams)) {
    return columns.map(item => {
      const newItem = deepClone(item);
      item.colRenderer && (newItem.colRenderer = item.colRenderer);
      item.render && (newItem.render = item.render);
      item.sortFn && (newItem.sortFn = item.sortFn);
      if (newItem.dataKey in sizeParams) {
        if (newItem.sizeParams) {
          newItem.sizeParams.defaultCustomWidth = sizeParams[newItem.dataKey];
        } else {
          newItem.sizeParams = {
            defaultCustomWidth: sizeParams[newItem.dataKey]
          };
        }
      }
      return newItem;
    });
  }
  return columns;
};

export const makeColumn = ({ columns, settingColumns, searchKey = 'text', sizeParams = null }) => {
  const newColumns = [];
  if (settingColumns?.length) {
    const columnsObj = {};
    // eslint-disable-next-line no-return-assign
    columns.forEach((item, index) => columnsObj[item[searchKey]] = { ...item, index });

    const stateColumnsList = settingColumns.map(item => item.name);
    const newAddedColumns = Object.keys(columnsObj).filter((item) => !stateColumnsList.includes(item));

    settingColumns.forEach(item => {
      // eslint-disable-next-line no-prototype-builtins
      if (item.selected && columnsObj.hasOwnProperty(item.name)) {
        newColumns.push(columnsObj[item.name]);
      }
    });
    if (newAddedColumns.length) {
      newAddedColumns.forEach(item => newColumns.splice(columnsObj[item].index, 0, columnsObj[item]));
    }
  }

  if (newColumns.length) {
    // eslint-disable-next-line no-param-reassign
    newColumns.forEach(item => delete item.index);
  }

  const data = setDefaultSize({
    columns: settingColumns?.length ? newColumns : columns,
    sizeParams
  });

  return data;
};
