import { deepClone } from 'lib/helpers/makeData';

const alertsConfigs = {
  hourlyAlert: {
    marginWinners: {
      tabKey: 'marginWinners'
    },
    gameWinners: {
      tabKey: 'gameWinners'
    },
    winners: {
      tabKey: 'winners'
    },
    straightUpWinners: {
      tabKey: 'straightUpWinners'
    },
    advantagePlay: {
      tabKey: 'advantagePlay'
    }
  },
  dailyAlert: {
    oppositeBettingAccount: {
      tabKey: 'oppositeBettingAccount'
    },
    suspiciousTips: {
      tabKey: 'suspiciousTips'
    },
    robotPlay: {
      tabKey: 'robotPlay'
    }
  }
};

const alertInitialState = {
  hourlyAlert: {
    alerts: {
      data: null,
      status: null,
      error: null,
      totalCount: null
    },
    alert_review: {
      data: null,
      status: null,
      error: null
    },
    alert_review_action: {
      data: null,
      status: null,
      error: null
    },
    review_history: {
      status: null,
      error: null,
      data: null
    },
    statistics: {
      gameType: {
        status: null,
        error: null,
        data: null
      },
      table: {
        status: null,
        error: null,
        data: null
      },
      dealer: {
        status: null,
        error: null,
        data: null
      }
    },
    trueCount: {
      status: null,
      error: null,
      data: null
    }
  },
  dailyAlert: {
    alerts: {
      data: null,
      status: null,
      error: null,
      totalCount: null
    },
    alert_review_action: {
      status: null,
      error: null
    },
    alert_review: {
      data: null,
      status: null,
      error: null
    },
    review_history: {
      status: null,
      error: null,
      data: null
    },
    additionalInfo: {
      status: null,
      error: null,
      data: null
    },
    statistics: {
      gameType: {
        status: null,
        error: null,
        data: null
      },
      table: {
        status: null,
        error: null,
        data: null
      },
      dealer: {
        status: null,
        error: null,
        data: null
      }
    }
  }
};

export const dailyInitialStateByKey = {
  oppositeBettingAccount: deepClone(alertInitialState.dailyAlert),
  suspiciousTips: deepClone(alertInitialState.dailyAlert)
};

export const hourlyInitialStateByKey = {
  marginWinners: deepClone(alertInitialState.hourlyAlert),
  gameWinners: deepClone(alertInitialState.hourlyAlert),
  winners: deepClone(alertInitialState.hourlyAlert),
  straightUpWinners: deepClone(alertInitialState.hourlyAlert),
  advantagePlay: deepClone(alertInitialState.hourlyAlert)
};

export class alertsFactory {
  static createInitialAlertsMonitor(type) {
    const alerts = {};
    Object.keys(alertsConfigs[type]).forEach(name => { alerts[name] = deepClone(alertInitialState[type]); });
    return alerts;
  }
}
